// import React from "react";
import { StyledPartnerSubscriptionHeader, StyledPartnerSubscriptionHeaderName, StyledPartnerSubscriptionHeaderTags } from "./PartnerSubscriptionHeader.styles";

const PartnerSubscriptionHeader = ({name, tags}) => {
	return (
		<StyledPartnerSubscriptionHeader>
			<StyledPartnerSubscriptionHeaderName>{name}</StyledPartnerSubscriptionHeaderName>
			<StyledPartnerSubscriptionHeaderTags>{tags}</StyledPartnerSubscriptionHeaderTags>
		</StyledPartnerSubscriptionHeader>
	);
};

export default PartnerSubscriptionHeader;
