import {useEffect} from "react";
import {useState} from "react";
import {StyledHeaderTabs} from "./HeaderTabs.styles";

const HeaderTabs = ({tabs, toggleTabs, events = {}}) => {
	const [active, setActive] = useState(tabs[0]);
	const [defaultTabActive, setDefaultTabActive] = useState(false);

	useEffect(() => {
		if (defaultTabActive == true) {
			if (active in events && "dispatch" in events) {
				if (
					"type" in events[active] &&
          "event" in events[active] &&
          Object.keys(events[active].event(active)).length > 0
				) {
					let _event = {
						event: events[active].event(active),
						type: events[active].type,
					};
					events.dispatch(_event);
				}
			}
		} else {
			setDefaultTabActive(true);
		}
	}, [active]);

	return (
		<StyledHeaderTabs>
			{tabs.map((tab) => (
				<div
					key={tab}
					onClick={() => {
						setActive(tab);
						toggleTabs(tab);
					}}
					className={`tab ${active === tab ? "active" : ""}`}>
					{tab}
				</div>
			))}
		</StyledHeaderTabs>
	);
};

export default HeaderTabs;
