
import { StyledPartnerBranding } from "./PartnerBranding.styles";

const PartnerBranding = ({ providerSlogan, image}) => {
	
	return (
		<StyledPartnerBranding image={image}>
			<div className="logo">

			</div>
			<div className="info">
				<span className="welcome-action">
					{providerSlogan}
				</span>
				<span className="sub-info">
              Data charges may apply
				</span>
			</div>
		</StyledPartnerBranding>
	);
};

export default PartnerBranding;
