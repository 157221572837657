import { useState } from "react";
import { StyledIosPopup } from "./IosPopup.styles";
import logo from "../../../assets/images/MTNPlayLogo.png";

// const Loader = styled.div`
// display: flex;
// padding: 14px;
// justify-content: center;
// width: 4px;
// height: 4px;
// `;


const IosPopup = () => {

	



	  const [ displayPopup ] =  useState(true);


	return (

	// <StyledIosPopup >
			
	// 	<div >


	//         <div className="tag-description-sub2">
	//                 SUBSCRIBE
	//         </div>
	// 	</div>



		
	// </StyledIosPopup>

	// <Loader>
	
	// <img alt="" width="20" height="20" src={logo}/>
		
	// </Loader>

	// <Loader> <img id="myimg"
		
		// alt="Van Gogh self-portrait"
		// style="border:4px solid teal; padding:15px;"    alt="" width="20" height="20" /> </Loader>
		<StyledIosPopup>
			{ displayPopup &&
		<div>
			<div className="upper">
				<img alt="" width="100" height="100" src={logo}/>
			
			
				{/* <img style={{ marginLeft:80, position: 'absolute'}} alt="" width="18" height="18" src={close}/> */}
			

				<p className="upper-desc">Install Play</p>
			</div>
			{/* <div className="lower">
				<p className="lower-desc">
					Get the freshest content delivered directly to your device.
					<span className="lower-desc-low" >
					Tap and “Add to Homescreen”
					</span>
				</p>
			
			</div> */}
		</div>
			}

		</StyledIosPopup>
	);
};

export default IosPopup;
