import { StyledCloseBar } from "./DefaultCloseBar.styles";

const DefaultCloseBar = ({title, onClose}) => {

	return (
		<StyledCloseBar>
			<div className="titles">
				<span className="title"><b>{title}</b></span>
				{/* <span className="description">{extra.desc}</span> */}
			</div>
			<div onClick={onClose} className="icon close"></div>
		</StyledCloseBar>
	);
};

export default DefaultCloseBar;
