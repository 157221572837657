import {useState,useEffect} from "react";
import {HeaderTabs} from "./HeaderTabs";
import {StyledTabbedList} from "./TabbedListPlay.styles";

const TabbedListPlay = ({ tabs = {}, tab_events = {}}) => {
	const [chosenTab, setChosenTab] = useState(tabs ? tabs[0] : "Subscritions");

	useEffect(() => {
	}, [chosenTab]);
	return (
		<StyledTabbedList>
			<HeaderTabs
				toggleTabs={setChosenTab}
				tabs={tabs ?? ["Subscritions", "Purchases"]}
				events={tab_events}
			/>
			{chosenTab}
			{/* <SubComponent
        title={`${
          items ? items[chosenTab]?.length ?? 0 : 0
        } ${chosenTab} ${type}`}
         items={chosenTab}
         events={sort_events}
      /> */}
		</StyledTabbedList>
	);
};

export default TabbedListPlay;
