// import React from "react";
import {StyledView } from "./SubView.styles";
import { Border } from "..";

const Menu = ({item}) => {

	return (
		<StyledView >
			<div className="menu-item">
				<div className="sub-icon"></div>
				<div className="content">
					<div className="menu-details">
						<div className="title">{item?.title}</div>
						<div className="price">{item?.price}</div>
						<span className="expiry">{item?.expiry}</span>
					</div>
					<div className="icon menu-icon"></div>
				</div>
			</div>
			<Border />
		</StyledView>
	
	);
};

export default Menu;
