// import React from "react";
import {StyledTermsAndConditions } from "./TermsAndConditions.styles";

const TermsAndConditions = () => {

	return (
    <StyledTermsAndConditions>
      <h1 style={{ padding: "20px", textAlign: "center" }}>
        MTN Play Website User Terms and Conditions{" "}
      </h1>
      <span>
        <p>
          Please read the terms and conditions below carefully before you start
          to use the MTN Play website and application (“Website and/or App”). If
          you do not agree to any of the terms below, you must not use the
          Website or App. If you have already installed it, you must immediately
          uninstall it. If you continue to use the Website or App, this will be
          treated as confirmation that you have read and agree to be bound by
          all of the terms and conditions below. 
        </p>
        <br />
        <br />
        <p>1. Your agreement to these Website User Terms and Conditions</p>
        <br />
        <p>
          1.1 SCANCOM PLC (MTN GHANA), registration number PL000322016, a public
          limited company incorporated under the laws of the Republic of Ghana
          (“we”, “us” and “our”) makes the MTN Play website (the “Website“)
          available for use on mobile devices that are supported by us.{" "}
        </p>
        <br />
        <p>
          1.2 The Website is made available by us to you. These Website or App
          User Terms and Conditions (the “Website and/or App Terms “) govern
          your relationship with us and specify how you are permitted to access
          and use the Website and/or App and what you are specifically
          restricted from doing.
        </p>
        <br />
        <p>
          1.3 These Website and/or App Terms will apply to you from the moment
          that you first access, download, install and/or use the Website or App
          and its content.  Similarly, the privacy policy made available to you
          when you access the Website (including its content) will apply to you
          from the moment you first access, download install and/or use the
          Website or App (including its content), (the “Privacy Policy“). The
          Privacy Policy forms part of these Website or App Terms.
        </p>
        <br />
        <p>
          1.4 We may update these Website or App Terms. When we do this, we will
          post the revised Website or App Terms on our website.
        </p>
        <br />
        <p>
          1.5 It is your responsibility to read the updated Website or App Terms
          and understand the changes to them.  If you disagree with anything in
          the updated Website or App Terms, you must discontinue your use of the
          Website or App.  If you continue to use the Website or App following
          the update, we will treat this as confirmation that you have read,
          understood and agreed to the updated Website or App Terms.
        </p>
        <br />
        <p>
          1.6 You hereby warrant to MTN that you have the required legal
          capacity to enter into and be bound by a contract. Minors must be
          assisted by their legal guardians when reading these Website or App
          Terms and using the Website and/or App. If you are unsure whether you
          have the legal capacity to enter into contracts, please ask someone to
          assist you with this information before continuing to use this Website
          or App.{" "}
        </p>
        <br />
        <p>
          1.7 If you do not agree with any provision contained in these Website
          or App Terms, please do not continue to log-on to this App or stop
          using or accessing this Website or App immediately.
        </p>
        <br />
        <p>
          1.8 We may translate these Website or App Terms into multiple
          languages. If there are any differences between the English version
          and any other language version of these Website or App Terms, the
          English version will be the controlling one (to the extent this is
          permitted by applicable laws and regulations).
        </p>
        <br />
        <p>
          2.1 The using, accessing or viewing of the Website or App requires an
          internet connection. You are responsible for paying any charges to
          your service provider and/or any other third party that may be
          applicable to your use of data, voice and/or text services when using
          the Website or App. {" "}
        </p>
        <br />
        <p>
          2.2 You may only use the Website for your own private, non-commercial
          use.
        </p>
        <p>
          2.3 Currently, you can use the Website or App to access Content
          relating to gaming, video, music and other lifestyle content.
        </p>
        <br />
        <p>
          2.4 We may update the Website or App and any information or material
          on it at any time, but we give no guarantee that such updates will
          continue to support your mobile device. We will however have no
          responsibility to provide any corrections, updates, or releases in
          connection with the Website or App.
        </p>
        <br />
        <p>
          2.5 The Website or App may not operate or function properly or at all
          if you have not installed the latest upgrade or are not using the
          latest version of the Website or App or are not using the appropriate
          browser.
        </p>
        <br />
        <p>
          2.6 We may terminate, suspend, withdraw or restrict the availability
          of all or any part of the Website or App or add, change or remove any
          features of the Website or App at any time and for any reason. You
          understand and agree that we may do so without notifying you in
          advance.
        </p>
        <br />
        <p>
          2.7 The Website or App is not intended for use in any country where
          its use or content would violate local law or would subject us to any
          regulations or liability there. We reserve the right to limit our
          provision of the Website or App in any country at any time, if we
          consider it appropriate in the circumstances.
        </p>
        <br />
        <p>
          3.1 You must register with us and create an account in order to access
          and use the Website or App. You may choose or be allocated a password
          in order to enable you to gain access to certain parts of this Website
          or App or the Content ("your Password"). MTN may, in its sole
          discretion, at any time and for any reason revoke your Password and
          consequently your access to certain parts of this Website or App or
          the Content.
        </p>
        <br />
        <p>
          3.2 You must keep your Password secret and confidential. You must
          immediately notify MTN in writing if the secrecy or confidentiality of
          your Password is compromised or if your Password is disclosed to any
          unauthorised person. This website's administrator may be contacted
          at [webmaster@mtn.co.za]. You should not download or use the App on a
          mobile device that you share with anybody else.
        </p>
        <br />
        <p>
          3.3 You must at all times keep your mobile device safe and take steps
          to prevent its fraudulent use.
        </p>
        <br />
        <p>3.4 You are responsible for: </p>
        &nbsp; &nbsp; &nbsp; &nbsp;3.4.1 &nbsp; keeping access to your account
        safe and secure and making sure that it is not accessed by or accessible
        to others;
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;3.4.2 &nbsp; all activity taking place using
        the Website or App on your mobile device or through your account; and
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;3.4.2 &nbsp; making sure information shown or
        stored on your mobile device (including any Content) is kept secure.
        <br />
        <br />
        <p>
          3.5 You will be responsible and wholly liable for any use or misuse of
          your Password. Any actions or omissions committed through the use of
          your Password will be considered to have been committed by you. You
          specifically indemnify MTN for any claim, liability, damage or loss
          arising as a result of the use or misuse of your Password.
        </p>
        <br />
        <p>3.6 If your mobile device is lost or stolen:</p>
        &nbsp; &nbsp; &nbsp; &nbsp;3.6.1 &nbsp; you will not be able to access
        or use the App unless you have purchased a new phone, downloaded the
        App, created a password, logged onto the App using the password and
        changed your password if required. You may delete your App account in
        the manner specified in these Website and/or App Terms. Similarly, we
        may suspend your access to the App, or delete your App account, in the
        manner specified in these Website and App Terms.
        <br />
        <br />
        <p>
          4.1 Any information, materials, communications, correspondence and
          other content that is sent, uploaded, submitted or communicated via
          the Website by us or you or other users of the Website (collectively,
          the “Content“) will be encrypted end-to-end, except when the Content
          is sent by SMS.{" "}
        </p>
        Except for user content, all of the Content on our Website and/or App,
        including text, software, scripts, code, design, graphics, photos,
        sounds, applications, interactive features, articles, news stories is
        owned by Us or third parties we license Content from and is protected by
        copyright, trademark, patent and other laws. We reserve all rights not
        expressly described in these Website or App Terms. MTN is an inclusive,
        non-discriminatory, transformative, family-oriented brand and will not
        tolerate any Content that contravenes these principles. You understand
        and agree that: we will not verify or approve any Content, and we are
        not responsible for the suitability, accuracy, availability, correctness
        or completeness of any Content. We reserve the right to review and
        remove any Content that may be identified by us, or reported to us, as
        being inappropriate or abusive or in breach of these Website or App
        Terms; the views and opinions expressed by you and/or any views or
        opinions expressed by other users of the Website or App or in any
        Content do not represent our views or opinions; and we are not
        responsible for, and will have no liability for, any Content which is
        offensive, or which violates any laws or your rights or the rights of
        others. You must at all times make sure that you have all necessary
        rights, licences, consents and permissions prior to sending, submitting,
        uploading or otherwise communicating any Content via the Website or App.
        4.6 You must report to us any Content or behaviour that is inappropriate
        or abusive or which you think is not consistent with these Website or
        App Terms. You can do so by contacting us on mtnghlegal.GH@mtn.com. In
        the event that you report any Content to us (or should any other user
        report your Content to us), we will access and review such Content in
        order to investigate the complaint. 4.7 We may investigate and/or
        determine that Content uploaded via the Website or App contravenes these
        Website or App Terms. We may take any action that we deem necessary
        where we identify Content or user behaviour that contravenes these
        Website or App Terms, including, for example, suspending a users’ access
        to the Website or App, or suspending/deleting their account. We may also
        contact and report user behaviour information to law enforcement
        agencies in accordance with applicable laws. For avoidance doubt, if
        prohibited Content is made available, we may elect to disable or remove
        that Content, in our sole and absolute discretion.  We may also elect to
        engage with the operator in respect of such Content. You must not do (or
        try to do), and you must not encourage or help anyone else do, any of
        the following: alter, modify or adapt all or any part of the Website or
        App; transfer or otherwise allow any other person to access or use your
        account/login details; use the Website or App in a way which breaches
        any law, rule, order or regulation (whether the violation of law is
        intended or not) or in a way that contravenes these Website and App
        Terms or the Privacy Policy; use the Website or App in any way that
        causes, or is likely to cause, the Website or App or access to the
        Website or App for anyone to be interrupted, damaged or slowed in any
        way; use the Website or App for commercial purposes; use the Website or
        App to send bulk messages; use the Website or App to send unsolicited
        advertising or promotional material for personal or commercial
        solicitation including, for example, “spam”, “junk mail”, “chain
        letters” or “pyramid schemes”; and use the Website or App to knowingly
        send, upload or otherwise communicate any Content that contains viruses,
        Trojan horses, ransomware, malware, worms, time bombs, keystroke
        loggers, spyware, adware or any other harmful programs or similar
        computer code designed to adversely affect the operation of any computer
        software or hardware; or use the Website or App in any way not expressly
        authorised by us. The Privacy Policy made available to you when you
        access the Website or App will apply to you from the moment you first
        access the Website or App and download, install, access and/or use the
        Content. The Privacy Policy forms part of these Website and App Terms.
        In order to register and be able to access and use the Website or App,
        you will be asked to provide certain personal data, such as your name
        and your mobile number. We will only get, use and process such phone
        numbers, contact details and other information to make the Website or
        App and all of its functionality available to you and only to the extent
        that this will not put us in violation of any applicable laws or
        regulations.   We will request your permission to do this. By accessing
        and using the Website or App you consent to our use of your personal
        data for the purposes of running competitions on the Website or App and
        determining the winners, the personal data used for this will consist of
        your name, mobile number and email address. By using the Website or App
        you consent to our use of your personal data. For more information about
        how your personal data is used, please see our Privacy Policy. Links to
        and from this Website or App to other websites belonging to or operated
        by third parties do not constitute an endorsement by MTN of such linked
        websites or their content nor do they constitute any association by MTN
        with their owners or operators. MTN has no control over such linked
        website and is not responsible or liable for any content, goods or
        services available on or through any such linked websites or for any
        damage, damages or loss caused or alleged to be caused by or in
        connection with your use of or reliance on any such content, goods or
        services available on or through any such linked websites. You agree
        that where you access linked websites, you do so entirely at your own
        risk. Your interaction, correspondence or business dealings with third
        parties which are referred to or linked to or from this Website or App
        is entirely at your own risk and are solely between you and such third
        party including the acquisition, disposal, payment and delivery of any
        goods or services, and any terms, conditions, warranties or
        representations associated with such interaction, correspondence or
        business dealings. You are solely responsible for identifying and
        familiarising yourself with any terms and conditions which will govern
        your relationship with such third party. You agree that We shall not be
        responsible or liable for any damage, damages or loss caused or alleged
        to be caused by or in connection with your interaction, correspondence
        or business dealings with such third parties. Your mobile network
        operator may charge you for standard call and text and/or mobile data
        charges when accessing and using the Website or App and these charges
        may vary if you access the Website when abroad. You are responsible for
        these charges and any other fees (including but not limited to any
        carrier and roaming fees) or taxes in connection with your use of the
        Website or App. We are the owner (or the licensee) of all intellectual
        property rights in and to the Website or App, and in the material or
        information published on it (apart from the Content). That material and
        information includes works protected by copyright laws around the world.
        You must not (or try to), and will not encourage or help anyone else to,
        do any of the following: copy or reproduce any part of the Website or
        App; remove or tamper with any copyright notice attached or contained
        within the Website or App; copy or reproduce any trademarks, service
        marks, logos or branding published or displayed in the Website or App;
        and reverse engineer, decompile, disassemble or decipher the Website or
        otherwise try to get the source code for (or any part of) the Website or
        App. We grant you a limited, non-exclusive, non-transferable,
        non-sublicensable, revocable (if you delete your account or if we
        terminate your right to use the Website or App) licence for the limited
        purpose of using the Website or App on your mobile device, always
        subject to these Website and App Terms. You grant us a non-exclusive,
        perpetual, royalty-free, transferable, sublicensable, worldwide,
        irrevocable and unrestricted licence to use, distribute, reproduce,
        display, store and copy any information (including any Content) that you
        create, upload, send, submit, store on or via the Website or App for the
        following purposes: to provide and operate the Website or App; to comply
        with any law, rule, order or regulation or the order of any court,
        enforcement agency or any other authority of competent jurisdiction; and
        to perform our obligations and exercise our rights under these Website
        and App Terms. 9.4 You must promptly inform us if you become aware that
        the Content does or may infringe the rights (including intellectual
        property rights) of others by sending an email to mtnghlegal.GH@mtn.com
        You can, at any time, request the deletion of your account the Website
        or App which will terminate your full use of the Website or App If you
        delete your account, this will erase your message history. However,
        please note that deleting your account will not delete the information
        other Website or App users retain in relation to you such as copies of
        messages or other information you have sent them. Additionally, we may
        also retain copies of certain information, such as log records, in the
        manner contemplated in the Privacy Statement. We can, at any time,
        suspend your use of the Website if: in our opinion, there are any
        security risks or concerns related to your use of the Website or App; we
        think or suspect, in our opinion, that the Website or App has been used
        fraudulently or in an unauthorised way, or in breach of these Website or
        App Terms or the Privacy Policy; there are obligations we have to meet
        under any laws and regulations; there are operational reasons that
        require us to suspend your use of the Website; or we consider it
        necessary in the circumstances. We may terminate your right to use the
        Website or App and/or delete your account with immediate effect and with
        or without notice (if): in our opinion, you have breached these Website
        and App Terms or the Privacy Policy; your actions have created, or are
        likely to create, harm, risk, or possible legal exposure for us, your
        mobile network provider or other Website or App users; or the
        manufacturer of your mobile device, the provider of your mobile device
        operating system and/or your mobile network operator no longer supports
        or permits the use of the App; we stop supporting the App on your mobile
        device or the operating system it runs on; whenever we may otherwise
        consider it necessary in the circumstances. Access to the Website or App
        may be interrupted or suspended from time to time, including, for
        example, for maintenance or upgrades. We will generally try to tell you
        of such interruptions and suspensions in advance.  We may notify you by
        sending you a message when you log in to the Website or App, through
        SMS, or in any other way. However, sometimes we will not have the
        opportunity or time to do that, including, for example, if we need to
        fix something urgently or if it would comprise our reasonable security
        measures or if it is unlawful for us to do it. We will not be liable for
        any losses to you or anyone else which result from any interruption or
        suspension of the Website or App service, or from you not being aware of
        it in advance. If your right to use the Website or App has been
        terminated or you have deleted your profile on the App: these Website
        and App Terms may no longer apply but any provisions of these Website
        and App Terms that by their nature should continue to apply after
        termination of these Website or App Terms will continue to apply. This
        includes (but is not limited to) all provisions relating to intellectual
        property (except for your license to use the Website or App, which will
        end automatically when your right to use the Website or App is
        terminated or your account is deleted), liability, indemnities and
        choice of law; and we can disable, reclaim and reuse your account name,
        user ID and other identifiers you have used in connection with the
        Website or App. The Website or App is provided “as is” and on an “as
        available” basis with no representations or guarantees as to performance
        or functionality. To the fullest extent permitted by law, we exclude all
        conditions, warranties, representations or other terms which may apply
        to the Website or App or any information or material on it, whether
        express or implied, including, for example, fitness for purpose,
        merchantability, freedom from viruses or other contaminating or
        destructive properties, availability, loss of data or
        non-infringement. You understand and agree that: communications sent
        over the internet are not guaranteed to be completely secure and may be
        subject to possible interception, monitoring, delay, disruption, loss or
        alteration; and we are not responsible for, and will have no liability
        in respect of, any interception or monitoring of communications or
        Content by third parties, or any delay or failure in transmitting
        communications or Content, or loss or alteration of any communications
        or Content sent or submitted via the Website or App. We do not guarantee
        the suitability, accuracy or completeness of any information or material
        we publish on the Website or App and we reserve the right to change it
        at any time without notice. To the fullest extent permitted by law, our
        total liability to you for any losses, damages, liabilities, costs,
        expenses or charges, whether in contract, tort, including negligence, or
        otherwise in connection with the Website or App and these Website and
        App Terms will not be greater than USD $50. This means that, in the
        event that you successfully pursue a claim against us, you will not be
        able to receive more than USD $50 unless the laws in your country do not
        allow this. To the fullest extent permitted by law, we will not be
        liable to you for: any losses, damages, liabilities, costs, expenses
        (including but not limited to legal fees) or charges (whether arising in
        contract, tort, including negligence, or otherwise); and/or any
        indirect, special, incidental or consequential damages or losses,
        including (but not limited to) loss of revenue, profit, business,
        reputation, anticipated savings or goodwill, arising from or in
        connection with: 11.4.2.1your use or attempted use of the Website or
        App; 11.4.2.2your inability to use the Website or App; 11.4.2.3any
        failure, interruption, unavailability, delay, defect, error or omission
        in the Website or App; and/or 11.4.2.4any other matters arising
        otherwise in connection with the Website or App, or these Website and
        App Terms, regardless of whether we had been advised of or were aware of
        the possibility of such losses or damages being incurred. Nothing in
        these Website and App Terms limits or excludes or limits our liability
        for fraud or fraudulent misrepresentation, personal injury or death
        caused by negligence, or any other liability that cannot be limited or
        excluded by law. This clause 11 (as well as every other term and
        condition in these Website and App Terms) apply to the fullest extent
        permitted by applicable laws. You may have additional rights under those
        laws, which differ or supplement the rights described above.  If this is
        the case, nothing in this clause 11 (or anything else in these Website
        and App Terms) should be interpreted to mean that you have forfeited
        these rights, where the law does not allow this. 12.       Indemnity You
        indemnify us against any liabilities, costs, expenses, damages and
        losses (including any direct, indirect or consequential losses, loss of
        profit, loss of reputation and all interest, penalties and legal and
        other costs and expenses) suffered or incurred by us as a result of or
        in connection with: any breach by you of these Website and App Terms;
        and/or any Content you send, upload, submit or otherwise communicate
        using the Website or App. You indemnify us against any loss or damage
        that we may suffer or incur arising from or in connection with any
        breach by you of our intellectual property rights.
        13.       Communications We may sometimes contact or notify you (in
        connection with these Website and App Terms or for other reasons) by
        showing a message when you log in to the Website or App, through SMS, or
        in any other way. We will communicate with you in English, and we expect
        that you will communicate with us in English as well. 14.       Abnormal
        and unforeseen circumstances We are not responsible if we do not comply
        with any of these Website and App Terms, or if the Website or App
        malfunctions or does not operate as expected: due to circumstances
        beyond our reasonable control; or due to our obligations under any laws
        or regulations. Nothing in these Website and App Terms will reduce any
        rights you are entitled to under the law in your country (including any
        rights under applicable consumer protection regulation). No person other
        than you and us will have any rights under the Contracts (Rights of
        Third Parties) Act 1999 [delete if laws of England and Wales are not
        applicable laws](or equivalent third party rights legislation in any
        other relevant country) to benefit from or enforce any of the provisions
        of these Website and Terms. These App Terms and any dispute or claim
        (including any non-contractual disputes or claims) arising from or in
        connection with them, or their subject matter or formation, will be
        governed by and interpreted in accordance with the laws of Ghana. The
        courts of Ghana will have exclusive jurisdiction to settle any dispute
        or claim (including any non-contractual disputes or claims) arising from
        or in connection with these App Terms or their subject matter or
        formation. 18.  You may contact Customercare.GH@mtn.com to report any
        disputes, issues or challenges with your use of the Website or App and
        we shall endeavour to amicably address your dispute, issue or challenge.
        If you want to contact us for any reason, please email our customer
        support team at 20.1 Under the laws of certain countries, we are
        required to point out certain important provisions in these terms and
        conditions to you.  The clauses which contain these important provisions
        and the reasons why they are important are set out below.  It is also
        very important that you read all of these clauses carefully and not just
        what we say below: Limitations of risk, legal responsibilities and
        liability. Clauses 2, 3, 4, 5, 10, 11, 14 are important because they
        limit and exclude obligations, liabilities and legal responsibilities
        that we and other persons or entities may otherwise have to you.   As a
        result of these clauses, your rights and remedies against us and these
        other persons and entities are limited and excluded. These clauses also
        limit and exclude your right to recover or make claims for losses,
        damages, liability or harm you or others may suffer. Assumptions of
        risk, legal responsibilities and liability by you. Clauses 3, 4, 5, 9,
        10, 12 are important because you take on risk, legal responsibilities
        and liability. As a result of these clauses, you may also be responsible
        for claims and other amounts.  You will also be responsible for, and you
        accept, various risks, damages, harm, and injury which may be suffered
        by you and others for what is stated in these clauses.
        Acknowledgements of fact by you. Clause 1 is important because it
        contains statements which are acknowledgements of fact by you. You will
        not be able to deny that the statements are true. We may take action
        against you and may have claims against you as a result of these
        statements. Your indemnification obligations. Clause 12 requires you to
        indemnify (hold harmless) us and other persons or entities against
        claims, loss, damages, and harm that may be suffered by us and other
        persons or entities as a result of the events set out in these clauses. 
        You also required to indemnify us and other persons and entities against
        claims for loss, damages, and harm that that may be made by any person
        or entity as a result of the events set out in these clauses.   This
        places various risks, liabilities, obligations and legal
        responsibilities on you and you will be responsible and liable for the
        payment of the value of the claims, loss, damages, and harm that that
        may be suffered or claimed.
        <br />
        <br />
        <h1>COOKIE POLICY AND NOTICE</h1>
        <br />
        <h2>WHAT ARE COOKIES AND WHY DO WE USE THEM?</h2>
        <br />
        We might use cookies and other techniques such as web beacons when you
        visit the website (“Website”). “Cookies” are small text files used by us
        to recognise repeat users, facilitate your on-going access to, and use,
        of our Website. Cookies allow us to track your usage behaviour and
        compile aggregate data that will help us to improve the functionality of
        our Website and content. For example, by storing your preferred settings
        for the next time you visit our Website. “Web beacons” (also called web
        bug, tracking bug, tag, web tag, page tag, tracking pixel, pixel tag,
        1×1 GIF, or clear GIF) are small, clear picture files used to follow
        your movements on our website. For example, by showing us whether you
        accessed certain content. The information we collect from cookies
        enables us to: tailor our Website to your personal needs; remember the
        notifications that you have been shown, so that you are not shown them
        again; help us link your browsing information to you and your personal
        information, for example, when you choose to register for a service;
        make improvements and updates to our Website based on the way you want
        to use them; and we generally do not use cookies to identify you
        personally. The type of information collected by cookies is not used to
        personally identify you.
        <br />
        <br />
        <h1>WHAT KIND OF COOKIES DO WE USE?</h1>
        <br />
        We use the following categories of cookies:
        <br />
        <br />
        <h4>2.1 Category 1: Necessary Cookies</h4>
        <br />
        Necessary cookies enable you to use our Website and all its features,
        such as enabling access to secure areas of the Website. Without these
        cookies you may not be able to use all the features of our Website.
        These cookies do not store any personally identifiable information.
        These cookies cannot be switched off from our systems.
        <br />
        <br />
        <h4>2.2 Category 2: Performance Cookies </h4>
        <br />
        Performance cookies collect information about how you use our Website so
        we can improve the Website for you in future. For example, they collect
        information on which pages you visit most often and any error messages
        you may get. The information collected by these cookies is aggregated
        and therefore anonymous. They do not collect any information that can
        identify you personally.
        <br />
        <br />
        <h4>2.3 Category 3: Functionality Cookies</h4>
        <br />
        Functionality cookies enable us to provide you with a more personalized
        and enhanced experience. They help us to remember data, such as your
        username and language preference, to save you having to enter them again
        the next time you visit our Website. The information these cookies
        collect is anonymous. They cannot track your browsing activity on other
        websites. Functionality cookies may be set by third party providers
        whose services we have added to our pages for your benefit.
        <br />
        <br />
        <h4>2.4 Category 4: Targeting/Advertising Cookies</h4>
        <br />
        We and our service providers may use advertising cookies to deliver
        advertisements that we believe are relevant to you and your interests.
        For example, we may use targeting or advertising cookies to customise
        the advertising and content you receive on our Website, to limit the
        number of times you see the same ads on our websites and to help measure
        the effectiveness of our advertising campaigns. They do not store
        personally identifiable information but are based on identifying your
        browser and internet device.
        <br />
        <br />
        <h4>2.5 Category 5: Social Media Cookies</h4>
        <br />
        Our website uses third-party cookies from social media sites such as
        Facebook, Twitter and LinkedIn to enable in-depth campaign reporting and
        to track social network users when they visit our Website, by using a
        tagging mechanism provided by those social networks. These cookies can
        also be used for event tracking and remarketing purposes. Any data
        collected with these tags will be used in accordance with our privacy
        policy which you can access with the social network's privacy policies.
        Our Website will not collect or store any personally identifiable
        information from the user, unless authorized by you or in compliance
        with the law.
        <br />
        <br />
        <h1>HOW CAN YOU MANAGE YOUR COOKIE SETTINGS?</h1>
        <br />
        To ensure you get the best possible experience when visiting our
        websites, we recommend that you accept cookies. However, you can opt-out
        of each cookie category (except strictly necessary cookies) by clicking
        on the “cookie settings” button or disable cookies in your web browser.
        Cookies may, however, be necessary to provide you with certain features
        available on our Website. If you disable cookies you may not be able to
        use some features, and your access to our Website will be limited.
      </span>
      {/* {items.map(v => (
				<div className="terms" key={v?.terms}>
					<h1 className="title">{v?.title}</h1>
					<div className="tncs">
						{v.terms?.map((q) => (
							<div className="tnc" key={q.title}>
								<h5 className="tnc-title">{q?.title}</h5>
								{q.desc?.map((r) => (
									<p className="tnc-details" key={r.details}>{r?.details}</p>
								))}
								<Border />
							</div>
						))}
					</div>
				</div>
			))} */}
    </StyledTermsAndConditions>
  );
};

export default TermsAndConditions;
