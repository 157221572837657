// import React from "react";
import { StyledPartnerSubscriptionInfo, StyledPartnerSubscriptionInfoNotes } from "./PartnerSubscriptionInfo.styles";

const PartnerSubscriptionInfo = ({notes}) => {
	return (
		<StyledPartnerSubscriptionInfo>
			{notes.map(note => (
				<StyledPartnerSubscriptionInfoNotes key={note}>{note}</StyledPartnerSubscriptionInfoNotes >
			))}
			
		</StyledPartnerSubscriptionInfo>
	);
};

export default PartnerSubscriptionInfo;
