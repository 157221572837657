import { useSelector, useDispatch } from "react-redux";
import { titles } from "../../../utils/mock";
import { toggleCategories, updateSubCategories } from "../../../utils/redux/actions/categories.actions";
import { StyledCategoryBar } from "./CategoryBar.styles";

const CategoryBar = (isHeighted=false) => {
	const dispatch = useDispatch();
	const {subCategories, isShowSubCategories} = useSelector((state) => state?.categories);
	
	return (
		<StyledCategoryBar>
			{(isShowSubCategories ?
				(<>
					<div className="bar-with-icon">
	  {subCategories.map((v) => (<div onClick={() => {
		  dispatch(toggleCategories());
		  isHeighted = false;
	  }} className="category" key={v.value}>{v.value}</div>))}
	  <div className="sub-icon icon" onClick={() => {
		  isHeighted = !isHeighted;
		  height = "300px";
	  }} ></div>
	  </div>
				</>) :
				(<>
	  {titles.map((v) => (<div onClick={() => {
						dispatch(updateSubCategories(v?.subCategories));
		  dispatch(toggleCategories());
		  isHeighted = false;
	  }} className="category" key={v.value}>{v.value}</div>))}
				</>)
			)}
		</StyledCategoryBar>
	);
};

export default CategoryBar;