export const LANDING_CATEGORIES = {
  VIDEOS: "VIDEOS",
  MUSIC: "MUSIC",
  MOVIES: "MOVIES",
  GAMES: "GAMES",
};

export const CONTENT_PROVIDERS = {
  WIFLIX: "Wi-Flix",
  DV: "PlayVOD",
};

export const PUSH_NOTIFICATION = {
  STORAGE_KEY: "playGeneratedNotificationPayload",
};

export const INPUT_EVENTS = {
  DELETE_EVENT: "deleteContentBackward",
  INSERT_EVENT: "insertText",
};

export const PWA_ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  AUTH_CALLBACK: "/auth/callback",
  LOGIN_REDIRECT: "auth/loginRedirect",
  PRIVACY_NOTICE: "/privacyNoticesScreen",
  OTP: "/Otp",
  HOME: "/home",
  SERVICE: "/serviceScreen",
  PAYMENTS: "/payments",
  CONTENT_INFO: "/ContentInfo",
  COMING_SOON: "/comingsoon",
  SERVICE_DETAILS_SCREEN: "/ServiceDetailsScreen",
  SERIES: "/series",
  SUBSCRIBE_CONFIRM: "/myplay",
  ITEMS: "/items/:id",
  TOPUP: "/topup",
  TOPUP_METHOD: "/methods",
  TOPUP_VOUCHER: "/voucher",
  SUBSCRIBE_SUCCESS: "/success",
  SUBSCRIBE_FAIL: "/fail",
  CATEGORIES: "/categories",
  MANAGE: "/settings",
  SUBSCRIPTIONS: "/subscriptions",
  PURCHASES: "/purchases",
  ACTIVE_SUBSCRIPTIONS: "/activesubscriptions",
  INACTIVE_SUBSCRIPTIONS: "/inactivesubscriptions",
  PENDING_SUBSCRIPTIONS: "/pendingsubscriptions",
  PROFILE: "/profile",
  FAQ: "/help",
  TERMS_AND_CONDITIONS: "/terms",
  MY_PLAY: "/myplay",
  CONTENT_PLAYER: "/ContentPlayer",
  LOADING: "/Loading",
  TRANSACTION_RES: "/TransactionResScreen",
  GENERIC_ERROR: "/generic",
  COMPLETE_ENRICH: "/complete/enrichment",
  PROTECTED: "/protected",
  SEARCH: "/search",
  TEST_AYOBA_VIDEO: "/test/ayoba/video",
  TEST_AYOBA_MSISDN: "/test/ayoba/msisdn",
  CONSENT: "/consent",
  LANGUAGES: "/languages",
  PRIVACY_NOTICE_VIEW: "/privacyNotice",
};

export const ITEM_TYPES = {
  MOVIE: "movies",
  MUSIC: "music",
  GAME: "games",
  VIDEO: "videos",
  SPORTS: "sports",
  TRENDING: "trending",
  OFFERS: "offers",
  LIFESTYLE: "lifestyle",
};

export const ITEM_TIERS = {
  FREE: "free",
  SUBSCRIPTION: "subscription",
  PURCHASE: "purchase",
};

export const RECOMMENDATIONS_HEADINGS = {
  popular_content: "Popular on Play",
  recent: "Recently added",
  trending: "Trending",
};

const HOST = window.location.host;
const urls = {
  ENDPOINT: {
    contentService: HOST.includes("uat")
      ? "https://content.uat.mtnplay.com"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://content.dev.mtnplay.com"
      : HOST.includes("sit")
      ? "https://content.sit.mtnplay.com"
      : "https://content-prod.mtnplay.com",
    subscriptionService: HOST.includes("uat")
      ? "https://subscription.uat.mtnplay.com"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://subscription.dev.mtnplay.com"
      : HOST.includes("sit")
      ? "https://subscription.sit.mtnplay.com"
      : "https://subscription-prod.mtnplay.com",
    userService: HOST.includes("uat")
      ? "https://user.uat.mtnplay.com"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://user.dev.mtnplay.com"
      : HOST.includes("sit")
      ? "https://user.sit.mtnplay.com"
      : "https://user-prod.mtnplay.com",

    uploadService: HOST.includes("uat")
      ? "https://upload.uat.mtnplay.com"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://upload.dev.mtnplay.com"
      : HOST.includes("sit")
      ? "https://upload.sit.mtnplay.com"
      : "https://upload-prod.mtnplay.com",
  },
};
export const uri = urls.ENDPOINT;

const analyticsUrls = {
  ENDPOINT: {
    environment: HOST.includes("uat")
      ? "uat"
      : HOST.includes("localhost") || HOST.includes("dev")
      ? "dev"
      : HOST.includes("sit")
      ? "sit"
      : "live",
    // endpoint: HOST.includes('uat') ? 'https://uat-mtn-play-gh-dot-mymtn-nextgen-ghana.ew.r.appspot.com/extract' : HOST.includes('prod') ? 'https://live-mtn-play-gh-dot-mymtn-nextgen-ghana.ew.r.appspot.com/extract' : HOST.includes('sit') ? 'https://uat-mtn-play-gh-dot-mymtn-nextgen-ghana.ew.r.appspot.com/extract' : 'https://uat-mtn-play-gh-dot-mymtn-nextgen-ghana.ew.r.appspot.com/extract',
    endpoint: HOST.includes("uat")
      ? "https://content.uat.mtnplay.com/api/sendanalytics"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://content.dev.mtnplay.com/api/sendanalytics"
      : HOST.includes("sit")
      ? "https://content.sit.mtnplay.com/api/sendanalytics"
      : "https://content-prod.mtnplay.com/api/sendanalytics",
  },
};

export const analyticUri = analyticsUrls.ENDPOINT;

// api/sendanalytics

export const ZIGI_SCRIPT_URL =
  HOST.includes("dev") || HOST.includes("dev")
    ? "https://chatbot-dev.mtn.com.gh/bm/"
    : "https://chatbot-stg.mtn.com.gh/bm/";

export const COUNTRIES = Object.freeze({
  "+260": {
    name: "Zambia",
    url: "https://www.mtn.zm/play/",
    abbreviation: "ZM",
    redirect: false,
    iso3: "ZMB",
  },
  "+245": {
    name: "Guinea Bissau",
    url: "https://mtngbissau.com/play/",
    abbreviation: "GW",
    redirect: true,
    iso3: "GNB",
  },
  "+234": {
    name: "Nigeria",
    url: "https://www.mtn.ng/play/",
    abbreviation: "NG",
    redirect: true,
    iso3: "NGA",
  },
  "+237": {
    name: "Cameroon",
    url: "https://mtn.cm/play/",
    abbreviation: "CM",
    redirect: false,
    iso3: "CMR",
  },
  "+231": {
    name: "Liberia",
    url: "https://lonestarcell.com/digital/",
    abbreviation: "LR",
    redirect: true,
    iso3: "LBR",
  },
  "+256": {
    name: "Uganda",
    url: "https://www.mtn.co.ug/mtn-play/",
    abbreviation: "UG",
    redirect: true,
    iso3: "UGA",
  },
  "+249": {
    name: "Sudan",
    url: "https://www.mtn.sd/mtn/play/",
    abbreviation: "SD",
    redirect: true,
    iso3: "SDN",
  },
  "+233": {
    name: "Ghana",
    url: "https://mtnplay.com",
    abbreviation: "GH",
    redirect: false,
    iso3: "GHA",
  },
  "+27": {
    name: "South Africa",
    url: "https://play.mtn.co.za/",
    abbreviation: "ZA",
    redirect: true,
    iso3: "ZAF",
  },
  "+55": {
    name: "Brazil",
    url: "https://www.mtn.zm/play/",
    abbreviation: "BR",
    redirect: false,
    iso3: "BRA",
  },
  "+242": {
    name: "Congo",
    url: "https://mtnplay.com",
    abbreviation: "COG", //changed from DRC to ISO3 code
    redirect: false,
    iso3: "COG",
  },
  "+225": {
    name: "CIV",
    url: "https://mtnplay.com",
    abbreviation: "CIV",
    redirect: false,
    iso3: "CIV",
  },
  "+268": {
    name: "Eswatini",
    url: "https://mtnplay.com",
    abbreviation: "SZ",
    redirect: false,
    iso3: "SWZ",
  },
});

export const TermsAndConditionsUrls = {
  gha: {
    en: "/terms-and-conditions/ghana-en.html",
    fr: "/terms-and-conditions/mtn-fr.html",
  },
  zmb: {
    en: "/terms-and-conditions/zambia-en.html",
    fr: "/terms-and-conditions/mtn-fr.html",
  },
  cmr: {
    en: "/terms-and-conditions/cameroon-en.html",
    fr: "/terms-and-conditions/cameroon-fr.html",
  },
  cog: {
    en: "/terms-and-conditions/congo-en.html",
    fr: "/terms-and-conditions/congo-fr.html",
  },
  civ: {
    en: "/terms-and-conditions/civ-en.html",
    fr: "/terms-and-conditions/civ-fr.html",
  },
  swz: {
    en: "/terms-and-conditions/eswatini-en.html",
  },
  uga: {
    en: "/terms-and-conditions/uga-en.html",
  },
};
export const PrivacyNoticesUrls = {
  civ: {
    en: "/privacy-policy/civ-en.html",
    fr: "/privacy-policy/civ-fr.html",
  },
  cmr: {
    fr: "/privacy-policy/cm-fr.html",
  },
  swz: {
    en: "/privacy-policy/eswatini-en.html",
  },
  uga: {
    en: "/privacy-policy/uga-en.html",
  },
};

const MVP_FR_FAQ = {
  topic: "FAQs et Aide sur MTN Play",
  questions: [
    {
      value: "Qu’est-ce que MTN Play?",
      showAnswer: false,
      answer:
        "MTN Play est un portail de contenus digitaux connectant les clients à du contenu mobile et de divertissement premium, accessible aux clients MTN via n'importe quel appareil mobile.",
    },
    {
      value: "Comment je paie pour un contenu?",
      showAnswer: false,
      answer:
        "Le contenu peut être payé avec du crédit de communication ou MoMo le cas échéant.",
    },
    {
      value: "Comment je m’abonne à du contenu?",
      showAnswer: false,
      answer:
        "Cliquez sur le bouton d'abonnement du contenu auquel vous souhaitez vous abonner. Les abonnements peuvent concerner un ensemble de services, mais ils seront déterminés par les services d'abonnement des fournisseurs de contenu.",
    },
    {
      value: "Comment je regarde un contenu?",
      showAnswer: false,
      answer:
        "En fonction du contenu que vous souhaitez regarder et de la chaine du fournisseur de contenu, vous pourriez être redirigé ou recevoir un lien vers une autre fenêtre pour commencer le visionnage.",
    },
    {
      value: "Serai-je facturé pour chaque contenu?",
      showAnswer: false,
      answer:
        "La navigation sur MTN Play est gratuite. L'abonnement au contenu déclenche une facturation sur une base quotidienne, hebdomadaire, mensuelle ou ponctuelle.",
    },
    {
      value: "Qui sont les fournisseurs de contenus?",
      showAnswer: false,
      answer:
        "Il y a différents fournisseurs de contenu avec lesquels MTN a conclu des accords pour garantir que le contenu soit accessible aux clients MTN via MTN Play. La plupart du contenu appartient et est géré par ces fournisseurs de contenu.",
    },
    {
      value:
        "Serai-je facturé en crédit de communication/données mobiles pour voir du contenu?",
      showAnswer: false,
      answer:
        "La navigation sur MTN Play est gratuite pour les clients MTN. Vous ne serez facturé qu’une fois abonné et redirigé vers votre contenu.",
    },
    {
      value: "Quelles sont les conditions générales d'utilisation de MTN Play?",
      showAnswer: false,
      answer:
        "Veuillez visiter la section « Gérer » de l'application et sélectionner Conditions générales d’Utilisation.",
    },
    {
      value: "Combien de temps aurai-je accès au contenu?",
      showAnswer: false,
      answer:
        "Le contenu peut être souscrit sur une base quotidienne, hebdomadaire, mensuelle ou ponctuelle. Cet abonnement est à renouvellement automatique sur certains territoires. Vous devrez vous désabonner du contenu pour ne plus être facturé pour celui-ci. La résiliation d'un abonnement est immédiate. Une fois que vous avez annulé un abonnement, vous n’y aurez plus accès ni la possibilité de consommer du contenu précédemment souscrit.",
    },
  ],
};

const MVP_EN_FAQ = {
  topic: "Help FAQ's",
  questions: [
    {
      value: "What is MTN Play?",
      showAnswer: false,
      answer:
        "MTN Play is a digital content hub connecting customers to premium mobile and entertainment content, accessible via any mobile device to MTN customers.",
    },
    {
      value: "How do I pay for content?",
      showAnswer: false,
      answer: "Content can be paid for using airtime or MoMo where applicable.",
    },
    {
      value: "How do I subscribe to content?",
      showAnswer: false,
      answer:
        "Click on the subscribe button from the content that you want to subscribe to. Subscriptions can be for a range of services but will be determined by the content partners subscription services.",
    },
    {
      value: "How do I watch content?",
      showAnswer: false,
      answer:
        "Depending on the content you want to watch and the content partner channel, you may be redirected or receive a link  to another window to begin viewing.",
    },
    {
      value: "Will I be charged for each item?",
      showAnswer: false,
      answer:
        "Browsing MTN Play is free of charge. Subscription to content will attract charging on a daily, weekly, monthly or once-off basis.",
    },
    {
      value: "What are content providers?",
      showAnswer: false,
      answer:
        "There are various content providers that MTN has agreements with to ensure content is made accessible to MTN customers through MTN Play. The content is is licensed or owned and maintained and managed  by these content providers.",
    },
    {
      value: "How do I create a shortcut on my device?",
      showAnswer: false,
      answer:
        "If you are opening MTN Play for the first time, you will be prompted to click and create a shortcut on your mobile phone. Alternatively click the ‘settings’ option of your browser and you will find an option to create a shortcut. On iOS, select the share button and then select 'Add to Home Screen'. On Android tap on the three dots and select install 'MTN Play PWA'.",
    },
    {
      value: "Will I be charged airtime / data to view content?",
      showAnswer: false,
      answer:
        "Browsing on MTN Play is free of charge to MTN customers. You will only be charged once you have subscribed and redirected to your content.",
    },
    {
      value: "What are the terms and Conditions of using MTN Play?",
      showAnswer: false,
      answer:
        "Please visit the 'Manage' section of the app and select Terms & Conditions.",
    },
    {
      value: "How long will I have access to content?",
      showAnswer: false,
      answer:
        "Content can be subscribed to on a daily, weekly, monthly or once-off basis. This subscription is on an auto-renewal basis in certain markets. You will need to unsubscribe to content to stop being charged for it. Cancelling a subscription is immediate. Once you have cancelled a subscription, you will not have access to it or the ability to consume previously subscribed to content.",
    },
  ],
};

export const FAQs = {
  gha: {
    en: {
      topic: "Help FAQ's",
      questions: [
        {
          value: "How do I login?",
          showAnswer: false,
          answer:
            "Go to the home page and click the login button. You may be prompted to input your mobile number and a pin that will be sent to your phone by MTN Play.",
        },
        {
          value: "How do I pay for content?",
          showAnswer: false,
          answer:
            "When you subscribe to content, you will be able to select your payment method as either airtime payment or MoMo",
        },
        {
          value: "How do I Subscribe?",
          showAnswer: false,
          answer:
            "Click on subscribe button from the content that you would want to subscribe ",
        },
        {
          value: "How do I watch content?",
          showAnswer: false,
          answer:
            "Depending on the content you want to watch, you may be redirected to another page to watch, or you may be sent an SMS with instructions to access the content or just click the content on MTN Play to watch",
        },
        {
          value: "Will I be charged for each item?",
          showAnswer: false,
          answer:
            "There is an advice of charge on each item where price and validity period is shown. Some items are free, some attract a price for various valid periods and it will be displayed as such",
        },
        {
          value: "How do I confirm a subscription?",
          showAnswer: false,
          answer:
            "Subscription is confirmed through two steps confirmation. Once you have clicked to subscribe, selected your subscription period and payment method, you will be prompted to confirm your subscription",
        },
        {
          value: "How do I unsubscribe from content?",
          showAnswer: false,
          answer:
            "Visit the 'manage' section of the app. Select subscriptions to manage any of your subscriptions",
        },
        {
          value: "How do I send feedback?",
          showAnswer: false,
          answer:
            "Visit the 'manage' section of the app. Select 'Help' > Feedback option",
        },
        {
          value: "How do I renew my subscription?",
          showAnswer: false,
          answer:
            "You may visit 'MANAGE' section, select the content  you would want to renew subscriptions for. For better customer experience, when you first time subscribe to content, make sure you select autonewal and MTN Play will automatically renew your subscribe everytime at the renewal time",
        },
        {
          value: "How to I view content I've suscribed to?",
          showAnswer: false,
          answer:
            "Click 'MY Play' at the bottom of navigation bar and you will be able to view a list of content that you have subscribed to",
        },
        {
          value: "What are content providers?",
          showAnswer: false,
          answer:
            "There are various content providers that MTN provide the content through MTN Play. Most of the content is owned and maintained by these content providers",
        },
        {
          value: "What is MTN Play?",
          showAnswer: false,
          answer:
            "MTN Play is a digital media service that provides various types of content",
        },
        {
          value: "How do I create a shortcut on my device?",
          showAnswer: false,
          answer:
            "If you are first time opening MTN Play, you will be prompted to click and create a shortcut on your mobile phone. Alternatively click settings option of your browser and you will find an option to create a shortcut. On iOS select the share button and then select 'Add to Home Screen'. On Android tap on the three dots and select install 'MTN Play PWA'",
        },
        {
          value:
            "If I didn’t get my MTN Play content, where can I get assistance?",
          showAnswer: false,
          answer: "Please call  100 from your MTN number",
        },
        {
          value: "Will I be charged airtime / data to view content?",
          showAnswer: false,
          answer:
            "Yes, data/airtime will be consumed when streaming certain services and content",
        },
        {
          value: "What are the terms and Conditions?",
          showAnswer: false,
          answer:
            "Please visit the 'manage' section of the app. Select Terms & Conditions",
        },
      ],
    },
    fr: {
      topic: "FAQs et Aide sur MTN Play",
      questions: [
        {
          value: "Qu’est-ce que MTN Play?",
          showAnswer: false,
          answer:
            "MTN Play est un portail de contenus digitaux connectant les clients à du contenu mobile et de divertissement premium, accessible aux clients MTN via n'importe quel appareil mobile. ",
        },
        {
          value: "Comment j’accède à MTN Play ?",
          showAnswer: false,
          answer:
            "La découverte de contenus digitaux sur MTN Play est gratuite pour tous les clients MTN et est accessible  à tout utilisateur mobile. Entrez simplement mtnplay.com dans votre navigateur.",
        },
        {
          value: "Comment je paie pour un contenu ?",
          showAnswer: false,
          answer:
            "Lorsque vous vous abonnez à un contenu, vous pouvez sélectionner l’option de paiement avec crédit de communication.",
        },
        {
          value: "Comment je regarde un contenu ?",
          showAnswer: false,
          answer:
            "En fonction du contenu que vous souhaitez regarder, vous serez redirigé vers une autre page pour regarder le contenu, vous pourrez également recevoir un SMS avec des instructions pour accéder au contenu, ou il vous suffira de cliquer sur le contenu sur MTN Play pour le regarder.",
        },
        {
          value: "Serai-je facturé pour chaque contenu?",
          showAnswer: false,
          answer:
            "La navigation sur MTN Play est gratuite. L'abonnement au contenu déclenche une facturation sur une base quotidienne, hebdomadaire, mensuelle ou ponctuelle. Des frais de données mobiles supplémentaires s'appliqueront également une fois l'abonnement effectué.",
        },
        {
          value: "Qui sont les fournisseurs de contenus?",
          showAnswer: false,
          answer:
            "Il y a différents fournisseurs de contenu avec lesquels MTN a conclu des accords pour garantir que le contenu soit accessible aux clients MTN via MTN Play. La plupart du contenu appartient et est géré par ces fournisseurs de contenu.",
        },
        {
          value: "Comment je crée un raccourci sur mon appareil?",
          showAnswer: false,
          answer:
            "Si vous ouvrez MTN Play pour la première fois, vous serez invité à cliquer et à créer un raccourci sur votre téléphone mobile. Vous pouvez également cliquer sur l'option « Paramètres » de votre navigateur et vous trouverez une option pour créer un raccourci. Sur iOS, sélectionnez le bouton Partager, puis sélectionnez « Ajouter à l'écran d'accueil ». Sur Android, appuyez sur les trois points et sélectionnez installer « MTN Play PWA.",
        },
        {
          value:
            "Serai-je facturé en crédit de communication/données mobiles pour voir du contenu ?",
          showAnswer: false,
          answer:
            "La navigation sur MTN Play est gratuite pour les clients MTN. Vous ne serez facturé qu’une fois abonné et redirigé vers votre contenu. Une fois abonné, les tarifs de données standard s'appliqueront.",
        },
        {
          value:
            "Quelles sont les conditions générales d'utilisation de MTN Play ?",
          showAnswer: false,
          answer:
            "Veuillez visiter la section « Gérer » de l'application et sélectionner Conditions générales d’Utilisation.",
        },
        {
          value: "Combien de temps aurai-je accès au contenu ?",
          showAnswer: false,
          answer:
            "Le contenu peut être souscrit sur une base quotidienne, hebdomadaire, mensuelle ou ponctuelle. Cet abonnement est à renouvellement automatique. Vous devrez vous désabonner du contenu pour ne plus être facturé pour celui-ci. La résiliation d'un abonnement est immédiate. Une fois que vous avez annulé un abonnement, vous n’y aurez plus accès ni la possibilité de consommer du contenu précédemment souscrit.",
        },
      ],
    },
  },
  zmb: {
    en: MVP_EN_FAQ,
  },
  cmr: {
    en: MVP_EN_FAQ,
    fr: MVP_FR_FAQ,
  },
  cog: {
    en: MVP_EN_FAQ,
    fr: MVP_FR_FAQ,
  },
  civ: {
    en: MVP_EN_FAQ,
    fr: MVP_FR_FAQ,
  },
  swz: {
    en: MVP_EN_FAQ,
  },
  uga: {
    en: MVP_EN_FAQ,
  },
};
