// import React from "react";
import { Link } from "react-router-dom";
import { StyledBanner, StyledOverlay } from "./Footer.styles";

const Footer = ({data, onClickHandler=()=>{}}) => {
	return (
		<StyledBanner onClick={onClickHandler} isOpacity={data.data.enableOverlay} image={data.imgUrl}>
			{data.data.enableOverlay ?
				<StyledOverlay>
					<span className="banner-question">{data.data.header}</span>
					<span className="banner-justification">
					 {data.data.body}
					</span>
					<Link to={data.data.link} style={{textDecoration: "none", color: "#000"}}><div className="outline-btn">{data.data.actionButton}</div></Link>
				</StyledOverlay>
				: <></>}
		</StyledBanner>
	);
};

export default Footer;
